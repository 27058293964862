export type RESTAURANT = {
  name: string;
  serves: string;
  price: 1 | 2 | 3 | 4 | 5;
  contact: string;
  address: string;
  addressPath: string;
  tripadvisor?: string;
  booking?: string;
  web?: string;
};

const SPLITSKA: RESTAURANT = {
  name: 'Splitska',
  serves: 'Brunch / Lunch',
  price: 2,
  contact: '+385 91 600 4862',
  address: 'Ul. Domovinskog rata 61, 21000, Split',
  addressPath: 'https://maps.app.goo.gl/r7c6HShBMQKkGzHKA',
  web: 'https://www.instagram.com/thisissplitska/?hl=en',
};

const LEVEL: RESTAURANT = {
  name: 'Level',
  serves: 'Brunch / Lunch / Dinner / Dinner show',
  price: 3,
  contact: '+385 99 341 4469',
  address: 'Put Brodarice 6, 21000, Split',
  addressPath: 'https://maps.app.goo.gl/1VYwGn1pAfFJXowV6',
  tripadvisor:
    'https://www.tripadvisor.com/Restaurant_Review-g295370-d27921671-Reviews-Level_Restaurant-Split_Split_Dalmatia_County_Dalmatia.html?m=19905',
  booking: 'https://www.levelrestaurant.hr/en/reservations/',
  web: 'https://www.levelrestaurant.hr/en/',
};

const DOLIS: RESTAURANT = {
  name: 'Dolis',
  serves: 'Lunch / Dinner',
  price: 3,
  contact: '+385 21 670 078',
  address: 'Ul. Zrinsko Frankopanska 52, 21000, Split',
  addressPath: 'https://maps.app.goo.gl/JfyyHZqH7iWQShfKA',
  tripadvisor:
    'https://www.tripadvisor.com/Restaurant_Review-g295370-d23314201-Reviews-Restaurant_Bar_Dolis_Split-Split_Split_Dalmatia_County_Dalmatia.html?m=19905',
};

const CORNARO_ROOFTOP: RESTAURANT = {
  name: 'Cornaro Rooftop',
  serves: 'Lunch / Dinner',
  price: 4,
  contact: '+385 21 644 200',
  address: 'Sinjska ul. 6, 21000, Split',
  addressPath: 'https://maps.app.goo.gl/EdfZUULxTGWDPyf96',
  tripadvisor:
    'https://www.tripadvisor.com/Hotel_Review-g295370-d6871450-Reviews-Cornaro_Hotel-Split_Split_Dalmatia_County_Dalmatia.html?m=19905',
  booking: 'https://book.easytablebooking.com/book/?id=1b2c5&lang=auto',
  web: 'https://cornarohotel.com/',
};

const ZRNO_SOLI: RESTAURANT = {
  name: 'Zrno Soli',
  serves: 'Lunch / Dinner',
  price: 4,
  contact: '+385 91 434 3050',
  address: 'Uvala Baluni 8, 21000, Split',
  addressPath: 'https://maps.app.goo.gl/ictP3PooS38DqR8j6',
  tripadvisor:
    'https://www.tripadvisor.com/Restaurant_Review-g295370-d2189494-Reviews-Zrno_Soli-Split_Split_Dalmatia_County_Dalmatia.html?m=19905',
  web: 'http://www.zrnosoli.hr/',
};

const SPERUN: RESTAURANT = {
  name: 'Šperun',
  serves: 'Lunch / Dinner',
  price: 3,
  contact: '+385 21 346 999',
  address: 'Šperun ul. 3, 21000, Split',
  addressPath: 'https://maps.app.goo.gl/TAt1bbPYFtBeFWZv9',
  tripadvisor:
    'https://www.tripadvisor.com/Restaurant_Review-g295370-d1101232-Reviews-Sperun_Eat_and_Drink-Split_Split_Dalmatia_County_Dalmatia.html?m=19905',
  web: 'https://www.facebook.com/Speruneatanddrink/?locale=hr_HR',
};

const LUCICA: RESTAURANT = {
  name: 'LUČICA',
  serves: 'Lunch / Dinner',
  price: 3,
  contact: '+385 21 386 763',
  address: 'Lučica 7, 21000, Split',
  addressPath: 'https://maps.app.goo.gl/M7H4M5NF3aJ4VVnN9',
  tripadvisor:
    'https://www.tripadvisor.com/Restaurant_Review-g295370-d4420266-Reviews-Restaurant_Lucica-Split_Split_Dalmatia_County_Dalmatia.html?m=19905',
  web: 'http://restoranlucica.com/',
};

const PLAN_B: RESTAURANT = {
  name: 'Plan B',
  serves: 'Lunch / Dinner',
  price: 2,
  contact: '+385 97 639 2003',
  address: 'Slobode ul. 16A, 21000, Split',
  addressPath: 'https://maps.app.goo.gl/FTKQ21E9ehMSzLTs5',
  booking: 'https://bookmeatable.com/restaurants/plan-b-pub-29?lang=en-gb',
  web: 'https://g.co/kgs/JngGtfw',
};

const BAJAMONTI: RESTAURANT = {
  name: 'Bajamonti',
  serves: 'Lunch / Dinner',
  price: 3,
  contact: '+385 21 341 033',
  address: 'Trg Republike 1, 21000, Split',
  addressPath: 'https://maps.app.goo.gl/Esn9kTuuwSDYbMGj8',
  tripadvisor:
    'https://www.tripadvisor.com/Restaurant_Review-g295370-d2320078-Reviews-Bajamonti-Split_Split_Dalmatia_County_Dalmatia.html?m=19905',
  booking: 'https://bookneat.eu/reservations/bajamonti/book',
  web: 'https://bajamonti.com/',
};

export const WALKING_DISTANCE: Array<RESTAURANT> = [SPLITSKA, LEVEL, DOLIS];
export const FINE_DINING: Array<RESTAURANT> = [CORNARO_ROOFTOP, ZRNO_SOLI];
export const SEAFOOD_LOCALFOOD: Array<RESTAURANT> = [SPERUN, LUCICA];
export const PIZZA_BURGER: Array<RESTAURANT> = [PLAN_B, BAJAMONTI];
